import React from "react";
import { FooterMe, Subscribe } from "../components";

const Cashout = () => {
  return (
    <>
      <Subscribe />
      <FooterMe />
    </>
  );
};

export default Cashout;
