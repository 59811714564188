import React from "react";
import styles from "./Privacies.elements";
import {
  FaChartBar,
  FaEnvelope,
  FaDatabase,
  FaTools,
  FaChartLine,
} from "react-icons/fa"; // Imported FaChartLine for AppsFlyer

// Destructure styled-components from the imported styles object
const {
  TermsContainer,
  Section,
  SubSection,
  List,
  ListItem,
  BoldText,
  Link,
  ItalicText,
  SectionHeader,
  ServiceContainer,
  ServiceCard,
  IconWrapper,
  ServiceTitle,
  ServiceItem,
  ServiceSubTitle,
  ServiceDetails,
  Header,
  Title,
  Subtitle,
  Footer,
} = styles;

const Privacies = () => {
  return (
    <TermsContainer>
      <Header>
        <Title>سياسة الخصوصية لـ Fluentfox</Title>
        <Subtitle>
          خصوصيتك مهمة بالنسبة لنا. هذه هي سياسة Fluentfox لاحترام خصوصيتك فيما
          يتعلق بأي معلومات قد نجمعها منك عبر موقعنا الإلكتروني،
          <Link
            href="https://www.fluentfox.net"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://www.fluentfox.net
          </Link>
          ، والمواقع الأخرى التي نملكها ونديرها.
        </Subtitle>
      </Header>

      <Section>
        <SectionHeader>ملخص السياسة</SectionHeader>
        <p>
          <BoldText>
            البيانات الشخصية تتم معالجتها للأغراض التالية وباستخدام الخدمات
            التالية:
          </BoldText>
        </p>

        <ServiceContainer>
          {/* Analytics ServiceCard */}
          <ServiceCard>
            <IconWrapper>
              <FaChartBar size={24} color="#4a90e2" />
            </IconWrapper>
            <ServiceTitle>التحليلات</ServiceTitle>
            <ServiceItem>
              <ServiceSubTitle>Firebase Analytics</ServiceSubTitle>
              <ServiceDetails>
                <BoldText>البيانات الشخصية:</BoldText> معلومات الجهاز؛ بيانات
                الاستخدام؛ بيانات الموقع
              </ServiceDetails>
            </ServiceItem>
            <ServiceItem>
              <ServiceSubTitle>AppsFlyer</ServiceSubTitle>
              <ServiceDetails>
                <BoldText>البيانات الشخصية:</BoldText> معلومات الجهاز؛ بيانات
                الاستخدام؛ بيانات الموقع.
              </ServiceDetails>
            </ServiceItem>
          </ServiceCard>

          {/* Communication with User ServiceCard */}
          <ServiceCard>
            <IconWrapper>
              <FaEnvelope size={24} color="#50e3c2" />
            </IconWrapper>
            <ServiceTitle>التواصل مع المستخدم</ServiceTitle>
            <ServiceItem>
              <ServiceSubTitle>
                قائمة البريد أو النشرة الإخبارية ونموذج الاتصال
              </ServiceSubTitle>
              <ServiceDetails>
                <BoldText>البيانات الشخصية:</BoldText> عنوان البريد الإلكتروني
              </ServiceDetails>
            </ServiceItem>
          </ServiceCard>
        </ServiceContainer>

        <ServiceContainer>
          {/* Infrastructure Monitoring ServiceCard */}
          <ServiceCard>
            <IconWrapper>
              <FaDatabase size={24} color="#f5a623" />
            </IconWrapper>
            <ServiceTitle>مراقبة البنية التحتية</ServiceTitle>
            <ServiceItem>
              <ServiceSubTitle>Crashlytics</ServiceSubTitle>
              <ServiceDetails>
                <BoldText>البيانات الشخصية:</BoldText> الموقع الجغرافي؛ معرفات
                للإعلان (مثل Google Advertiser ID)؛ أنواع مختلفة من البيانات كما
                هو محدد في سياسة الخصوصية للخدمة
              </ServiceDetails>
            </ServiceItem>
          </ServiceCard>

          {/* Contact Management and Messaging ServiceCard */}
          <ServiceCard>
            <IconWrapper>
              <FaTools size={24} color="#bd10e0" />
            </IconWrapper>
            <ServiceTitle>إدارة جهات الاتصال وإرسال الرسائل</ServiceTitle>
            <ServiceItem>
              <ServiceSubTitle>Mailchimp</ServiceSubTitle>
              <ServiceDetails>
                <BoldText>البيانات الشخصية:</BoldText> عنوان البريد الإلكتروني
              </ServiceDetails>
            </ServiceItem>
          </ServiceCard>
        </ServiceContainer>
      </Section>

      {/* Full Policy Section */}
      <Section>
        <SectionHeader>السياسة الكاملة</SectionHeader>

        <SubSection>
          <h3>مؤسسة سعود خالد فهد السلوم للتجارة</h3>
          <p>
            فلونت فوكس <br />
            الرياض، حي النفل
            <br />
            شارع الحالتمية
          </p>
          <p>
            <BoldText>البريد الإلكتروني للمالك:</BoldText>{" "}
            <Link href="mailto:admin@fluentfox.net">admin@fluentfox.net</Link>
          </p>
        </SubSection>

        <SubSection>
          <h3>أنواع البيانات المجمعة</h3>
          <p>
            من بين أنواع البيانات الشخصية التي يجمعها هذا التطبيق، سواءً بشكل
            مباشر أو من خلال أطراف ثالثة، هناك: الكوكيز؛ بيانات الاستخدام؛
            معرفات (مثل Google)؛ عنوان البريد الإلكتروني؛ .
          </p>
          <p>
            يتم تقديم تفاصيل كاملة عن كل نوع من البيانات الشخصية المجمعة في
            الأقسام المخصصة لهذه السياسة أو من خلال نصوص تفسيرية محددة تظهر قبل
            جمع البيانات. قد يتم تقديم البيانات الشخصية بحرية من قبل المستخدم،
            أو، في حالة بيانات الاستخدام، يتم جمعها تلقائيًا عند استخدام هذا
            التطبيق.
          </p>
          <p>
            ما لم يُذكر خلاف ذلك، جميع البيانات المطلوبة من قبل هذا التطبيق
            إلزامية وقد يجعل عدم تقديم هذه البيانات من المستحيل على هذا التطبيق
            تقديم خدماته. في الحالات التي يحدد فيها هذا التطبيق أن بعض البيانات
            ليست إلزامية، يكون للمستخدمين حرية عدم تزويد هذه البيانات دون عواقب
            على توفر أو وظيفة الخدمة.
          </p>
          <p>
            يُرحب بالمستخدمين الذين غير متأكدين من البيانات الشخصية الإلزامية
            بالتواصل مع المالك.
          </p>
          <p>
            أي استخدام للكوكيز – أو لأدوات التحليل أخرى – من قبل هذا التطبيق أو
            من قبل مالكي الخدمات الخارجية المستخدمة من قبل هذا التطبيق يخدم غرض
            تقديم الخدمة المطلوبة من قبل المستخدم، بالإضافة إلى أي أغراض أخرى
            موصوفة في هذا المستند.
          </p>
          <p>
            المستخدمون مسؤولون عن أي بيانات شخصية لأطراف ثالثة تم الحصول عليها
            أو نشرها أو مشاركتها من خلال هذا التطبيق.
          </p>
        </SubSection>

        <SubSection>
          <h3>طريقة ومكان معالجة البيانات</h3>
          <h4>طرق المعالجة</h4>
          <p>
            يتخذ المالك التدابير الأمنية المناسبة لمنع الوصول غير المصرح به أو
            الكشف أو التعديل أو التدمير غير المصرح به للبيانات. تتم معالجة
            البيانات باستخدام أجهزة الكمبيوتر و/أو الأدوات الممكّنة بتكنولوجيا
            المعلومات، وفقًا للإجراءات التنظيمية والطرق المتعلقة بالأغراض
            المحددة. بالإضافة إلى المالك، في بعض الحالات، قد تكون البيانات متاحة
            لأنواع معينة من الأشخاص المسؤولين أو الأطراف الخارجية (مثل مزودي
            الخدمات التقنية من أطراف ثالثة، شركات البريد، مزودي الاستضافة، شركات
            تكنولوجيا المعلومات، وكالات الاتصالات) المعينين، إذا لزم الأمر،
            كممعالجات بيانات من قبل المالك. يمكن طلب القائمة المحدثة لهذه
            الأطراف من المالك في أي وقت.
          </p>
          <h4>المكان</h4>
          <p>
            تتم معالجة البيانات في مكاتب التشغيل الخاصة بالمالك وفي أي أماكن
            أخرى تتواجد فيها الأطراف المشاركة في المعالجة.
          </p>
          <p>
            اعتمادًا على موقع المستخدم، قد تشمل عمليات نقل البيانات نقل بيانات
            المستخدم إلى دولة أخرى غير دولته. لمعرفة المزيد عن مكان معالجة هذه
            البيانات المنقولة، يمكن للمستخدمين الاطلاع على القسم الذي يحتوي على
            تفاصيل حول معالجة البيانات الشخصية.
          </p>
          <h4>مدة الاحتفاظ</h4>
          <p>
            ما لم يُذكر خلاف ذلك في هذا المستند، تتم معالجة وتخزين البيانات
            الشخصية للمدة المطلوبة للأغراض التي جُمعت من أجلها وقد يتم الاحتفاظ
            بها لفترة أطول بسبب الالتزامات القانونية السارية أو بناءً على موافقة
            المستخدمين.
          </p>
        </SubSection>

        <SubSection>
          <h3>أغراض المعالجة</h3>
          <p>
            يتم جمع البيانات المتعلقة بالمستخدم للسماح للمالك بتقديم خدمته،
            والامتثال لالتزاماته القانونية، والرد على طلبات التنفيذ، وحماية
            حقوقه ومصالحه (أو مصالح مستخدميه أو أطراف ثالثة)، واكتشاف أي نشاط
            خبيث أو احتيالي، بالإضافة إلى ما يلي: التحليلات، إدارة جهات الاتصال
            وإرسال الرسائل، التواصل مع المستخدم، التسويق والإعلانات، ومراقبة
            البنية التحتية.
          </p>
          <p>
            للحصول على معلومات محددة حول البيانات الشخصية المستخدمة لكل غرض،
            يمكن للمستخدم الرجوع إلى قسم "معلومات مفصلة عن معالجة البيانات
            الشخصية".
          </p>
        </SubSection>
      </Section>

      {/* Detailed Information on Processing */}
      <Section>
        <SectionHeader>معلومات مفصلة عن معالجة البيانات الشخصية</SectionHeader>
        <p>
          <BoldText>
            يتم جمع البيانات الشخصية للأغراض التالية وباستخدام الخدمات التالية:
          </BoldText>
        </p>

        {/* Analytics SubSection */}
        <SubSection>
          <h3>التحليلات</h3>
          <p>
            تمكن الخدمات الواردة في هذا القسم المالك من مراقبة وتحليل حركة
            المرور على الويب ويمكن استخدامها لتتبع سلوك المستخدم.
          </p>

          <List>
            <ListItem>
              <BoldText>Firebase Analytics (Google LLC)</BoldText>
              <p>Firebase Analytics هي خدمة تحليلات تقدمها Google LLC.</p>
              <p>
                تم تصميم هذه الخدمة لتحليلات تطبيقات الهواتف المحمولة ويمكنها
                جمع معلومات متنوعة حول هاتفك، كما هو موضح في سياسة خصوصية
                Firebase Analytics.
              </p>
              <p>
                على وجه الخصوص، يستخدم هذا التطبيق معرفات للأجهزة المحمولة (بما
                في معرف الإعلان لنظام Android) وتقنيات مشابهة للكوكيز لتشغيل
                خدمة Firebase Analytics. قد تشارك Firebase Analytics البيانات مع
                أدوات أخرى تقدمها Firebase/Google، مثل Crashlytics أو إعلانات
                Google. يمكن للمستخدم الاطلاع على سياسة الخصوصية هذه للحصول على
                شرح مفصل حول الأدوات الأخرى المستخدمة من قبل المالك.
              </p>
              <p>
                يمكن للمستخدمين إلغاء الاشتراك في بعض ميزات Firebase Analytics
                من خلال إعدادات الجهاز المطبقة، مثل إعدادات الإعلانات على
                الهواتف المحمولة أو باتباع التعليمات في أقسام أخرى متعلقة بـ
                Firebase في سياسة الخصوصية هذه، إذا كانت متوفرة.
              </p>
              <p>
                <BoldText>البيانات الشخصية المعالجة:</BoldText> الكوكيز؛ معرفات
                الجهاز الفريدة للإعلان (مثل Google Advertiser ID)؛ بيانات
                الاستخدام.
              </p>
              <p>
                <BoldText>مكان المعالجة:</BoldText> الولايات المتحدة –{" "}
                <Link
                  href="https://www.google.com/policies/privacy/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  سياسة الخصوصية
                </Link>
                .
              </p>
            </ListItem>

            {/* AppsFlyer ListItem */}
            <ListItem>
              <BoldText>AppsFlyer</BoldText>
              <p>
                AppsFlyer هي منصة تحليلات وتحديد نسب تثبيت التطبيقات تقدم خدمات
                تتبع أداء الحملات التسويقية وتحليل سلوك المستخدمين.
              </p>
              <p>
                تستخدم هذه الخدمة لجمع بيانات تتعلق بتفاعل المستخدمين مع التطبيق
                وتحسين الحملات التسويقية بناءً على هذه البيانات.
              </p>
              <p>
                <BoldText>البيانات الشخصية المعالجة:</BoldText> معلومات الجهاز؛
                بيانات الاستخدام؛ بيانات الموقع؛ معلومات التحليل مثل معرف الجهاز
                ومعرفات الإعلانات.
              </p>
              <p>
                <BoldText>مكان المعالجة:</BoldText> الولايات المتحدة –{" "}
                <Link
                  href="https://www.appsflyer.com/privacy-policy/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  سياسة الخصوصية
                </Link>
                .
              </p>
              <p>
                <BoldText>الأساس القانوني للمعالجة:</BoldText> المصلحة المشروعة
                للمالك في تحسين خدماته وتحليل فعالية الحملات التسويقية.
              </p>
              <p>
                يمكن للمستخدمين إلغاء تتبعهم عبر AppsFlyer من خلال إعدادات
                الجهاز أو من خلال روابط الإلغاء المتاحة في الاتصالات التسويقية.
              </p>
            </ListItem>
          </List>
        </SubSection>

        {/* Marketing and Advertising SubSection */}
        <SubSection>
          <h3>التسويق والإعلانات</h3>
          <p>
            تُستخدم الخدمات الواردة في هذا القسم لإدارة وتحليل حملات التسويق،
            ولتقديم إعلانات مستهدفة للمستخدمين.
          </p>

          <List>
            {/* You can add Marketing and Advertising services here if needed */}
          </List>
        </SubSection>

        {/* Contacting the User SubSection */}
        <SubSection>
          <h3>التواصل مع المستخدم</h3>

          <List>
            <ListItem>
              <BoldText>
                قائمة البريد أو النشرة الإخبارية (هذا التطبيق)
              </BoldText>
              <p>
                عند التسجيل في قائمة البريد أو النشرة الإخبارية، سيتم إضافة
                عنوان بريد المستخدم الإلكتروني إلى قائمة جهات الاتصال لأولئك
                الذين قد يتلقون رسائل بريد إلكتروني تحتوي على معلومات ذات طابع
                تجاري أو ترويجي تتعلق بهذا التطبيق. قد يتم أيضًا إضافة عنوان
                بريدك الإلكتروني إلى هذه القائمة نتيجة التسجيل في هذا التطبيق أو
                بعد إجراء عملية شراء.
              </p>
              <p>
                <BoldText>البيانات الشخصية المعالجة:</BoldText> عنوان البريد
                الإلكتروني.
              </p>
            </ListItem>

            <ListItem>
              <BoldText>نموذج الاتصال (هذا التطبيق)</BoldText>
              <p>
                عند ملء نموذج الاتصال ببياناتهم، يخول المستخدم هذا التطبيق
                لاستخدام هذه التفاصيل للرد على طلبات المعلومات أو الاقتباسات أو
                أي نوع آخر من الطلبات كما هو موضح في رأس النموذج.
              </p>
              <p>
                <BoldText>البيانات الشخصية المعالجة:</BoldText> عنوان البريد
                الإلكتروني.
              </p>
            </ListItem>
          </List>
        </SubSection>

        {/* Infrastructure Monitoring SubSection */}
        <SubSection>
          <h3>مراقبة البنية التحتية</h3>
          <p>
            يسمح هذا النوع من الخدمات لهذا التطبيق بمراقبة استخدام وسلوك مكوناته
            بحيث يمكن تحسين أدائه وتشغيله وصيانته واستكشاف الأخطاء وإصلاحها.
            تعتمد البيانات الشخصية المعالجة على خصائص وطريقة تنفيذ هذه الخدمات،
            التي وظيفتها هي تصفية أنشطة هذا التطبيق.
          </p>

          <List>
            <ListItem>
              <BoldText>Crashlytics (Google LLC)</BoldText>
              <p>Crashlytics هي خدمة مراقبة تقدمها Google LLC.</p>
              <p>
                <BoldText>البيانات الشخصية المعالجة:</BoldText> الموقع الجغرافي؛
                معرفات الجهاز الفريدة للإعلان (مثل Google Advertiser ID)؛ أنواع
                مختلفة من البيانات كما هو محدد في سياسة الخصوصية للخدمة.
              </p>
              <p>
                <BoldText>مكان المعالجة:</BoldText> الولايات المتحدة –{" "}
                <Link
                  href="https://www.google.com/policies/privacy/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  سياسة الخصوصية
                </Link>
                .
              </p>
            </ListItem>
          </List>
        </SubSection>

        {/* Managing Contacts and Sending Messages SubSection */}
        <SubSection>
          <h3>إدارة جهات الاتصال وإرسال الرسائل</h3>
          <p>
            يتيح هذا النوع من الخدمات إمكانية إدارة قاعدة بيانات لجهات اتصال
            البريد الإلكتروني أو جهات الاتصال الهاتفية أو أي معلومات اتصال أخرى
            للتواصل مع المستخدم. قد تقوم هذه الخدمات أيضًا بجمع بيانات حول تاريخ
            ووقت عرض الرسالة من قبل المستخدم، وكذلك عندما يتفاعل المستخدم معها،
            مثل النقر على الروابط المضمنة في الرسالة.
          </p>

          <List>
            <ListItem>
              <BoldText>Mailchimp (The Rocket Science Group, LLC.)</BoldText>
              <p>
                Mailchimp هي خدمة إدارة عناوين البريد الإلكتروني وإرسال الرسائل
                تقدمها The Rocket Science Group, LLC.
              </p>
              <p>
                <BoldText>البيانات الشخصية المعالجة:</BoldText> عنوان البريد
                الإلكتروني.
              </p>
              <p>
                <BoldText>مكان المعالجة:</BoldText> الولايات المتحدة –{" "}
                <Link
                  href="https://mailchimp.com/legal/privacy/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  سياسة الخصوصية
                </Link>
                .
              </p>
            </ListItem>
          </List>
        </SubSection>
      </Section>

      {/* Further Information for Users */}
      <Section>
        <SectionHeader>معلومات إضافية للمستخدمين</SectionHeader>

        {/* Legal Basis of Processing SubSection */}
        <SubSection>
          <h3>الأساس القانوني للمعالجة</h3>
          <p>
            قد يقوم المالك بمعالجة البيانات الشخصية المتعلقة بالمستخدمين إذا كان
            أحد الأمور التالية ينطبق:
          </p>
          <List>
            <ListItem>
              قدم المستخدمون موافقتهم لأغراض محددة واحدة أو أكثر.
            </ListItem>
            <ListItem>
              تقديم البيانات ضروري لتنفيذ اتفاق مع المستخدم و/أو لأي التزامات
              قبل تعاقدية.
            </ListItem>
            <ListItem>
              المعالجة ضرورية للامتثال لالتزام قانوني يخضع له المالك.
            </ListItem>
            <ListItem>
              المعالجة تتعلق بمهمة تُنفذ في المصلحة العامة أو في ممارسة السلطة
              الرسمية المخولة للمالك.
            </ListItem>
            <ListItem>
              المعالجة ضرورية لأغراض المصالح المشروعة التي يسعى إليها المالك أو
              طرف ثالث.
            </ListItem>
          </List>
          <p>
            في أي حال، سيساعد المالك بسرور في توضيح الأساس القانوني المحدد الذي
            ينطبق على المعالجة، وخاصة ما إذا كان تقديم البيانات الشخصية هو مطلب
            قانوني أو تعاقدي، أو مطلب ضروري للدخول في عقد.
          </p>
        </SubSection>

        {/* Further Information About Retention Time SubSection */}
        <SubSection>
          <h3>مزيد من المعلومات حول مدة الاحتفاظ</h3>
          <p>
            ما لم يُذكر خلاف ذلك في هذا المستند، تتم معالجة وتخزين البيانات
            الشخصية للمدة المطلوبة للأغراض التي جُمعت من أجلها وقد يتم الاحتفاظ
            بها لفترة أطول بسبب الالتزامات القانونية السارية أو بناءً على موافقة
            المستخدمين.
          </p>
          <p>لذلك:</p>
          <List>
            <ListItem>
              البيانات الشخصية المجمعة لأغراض تتعلق بتنفيذ عقد بين المالك
              والمستخدم ستحتفظ حتى يتم تنفيذ هذا العقد بالكامل.
            </ListItem>
            <ListItem>
              البيانات الشخصية المجمعة لأغراض المصالح المشروعة للمالك ستحتفظ
              طالما كان ذلك ضروريًا لتحقيق هذه الأغراض. يمكن للمستخدمين العثور
              على معلومات محددة بخصوص المصالح المشروعة التي يسعى إليها المالك في
              الأقسام ذات الصلة من هذا المستند أو عن طريق الاتصال بالمالك.
            </ListItem>
            <ListItem>
              قد يُسمح للمالك بالاحتفاظ بالبيانات الشخصية لفترة أطول كلما منح
              المستخدم موافقة على هذه المعالجة، طالما لم يسحب هذه الموافقة.
              علاوة على ذلك، قد يُطلب من المالك الاحتفاظ بالبيانات الشخصية لفترة
              أطول كلما كان ذلك مطلوبًا للامتثال لالتزام قانوني أو بناءً على أمر
              من سلطة.
            </ListItem>
          </List>
          <p>
            بمجرد انتهاء فترة الاحتفاظ، سيتم حذف البيانات الشخصية. لذلك، لا يمكن
            تطبيق حق الوصول، وحق الحذف، وحق التصحيح، وحق نقل البيانات بعد انتهاء
            فترة الاحتفاظ.
          </p>
        </SubSection>

        {/* The Rights of Users Based on GDPR SubSection */}
        <SubSection>
          <h3>
            حقوق المستخدمين بناءً على اللائحة العامة لحماية البيانات (GDPR)
          </h3>
          <p>
            يمكن للمستخدمين ممارسة حقوق معينة بشأن بياناتهم التي يعالجها المالك.
          </p>
          <p>
            على وجه الخصوص، للمستخدمين الحق في ما يلي، بقدر ما يسمح به القانون:
          </p>
          <List>
            <ListItem>
              <BoldText>سحب موافقتهم في أي وقت.</BoldText> للمستخدمين الحق في
              سحب الموافقة حيثما قدموا موافقة مسبقة على معالجة بياناتهم الشخصية.
            </ListItem>
            <ListItem>
              <BoldText>اعتراض على معالجة بياناتهم.</BoldText> للمستخدمين الحق
              في الاعتراض على معالجة بياناتهم إذا كانت المعالجة تتم على أساس
              قانوني غير الموافقة.
            </ListItem>
            <ListItem>
              <BoldText>الوصول إلى بياناتهم.</BoldText> للمستخدمين الحق في معرفة
              ما إذا كانت البيانات تتم معالجتها من قبل المالك، والحصول على إفصاح
              بشأن جوانب معينة من المعالجة، والحصول على نسخة من البيانات التي
              تتم معالجتها.
            </ListItem>
            <ListItem>
              <BoldText>التحقق وطلب التصحيح.</BoldText> للمستخدمين الحق في
              التحقق من دقة بياناتهم وطلب تحديثها أو تصحيحها.
            </ListItem>
            <ListItem>
              <BoldText>تقييد معالجة بياناتهم.</BoldText> للمستخدمين الحق في
              تقييد معالجة بياناتهم. في هذه الحالة، لن يعالج المالك بياناتهم لأي
              غرض آخر سوى تخزينها.
            </ListItem>
            <ListItem>
              <BoldText>حذف بياناتهم الشخصية أو إزالتها بطرق أخرى.</BoldText>{" "}
              للمستخدمين الحق في الحصول على حذف بياناتهم من المالك.
            </ListItem>
            <ListItem>
              <BoldText>استلام بياناتهم ونقلها إلى مسؤول بيانات آخر.</BoldText>{" "}
              للمستخدمين الحق في استلام بياناتهم في صيغة منظمة، شائعة الاستخدام،
              وقابلة للقراءة الآلية، وإذا كان ذلك ممكنًا تقنيًا، في نقلها إلى
              مسؤول بيانات آخر دون أي عائق.
            </ListItem>
            <ListItem>
              <BoldText>تقديم شكوى.</BoldText> للمستخدمين الحق في تقديم مطالبة
              أمام سلطة حماية البيانات المختصة.
            </ListItem>
          </List>
          <p>
            كما يحق للمستخدمين معرفة الأساس القانوني لنقل البيانات إلى الخارج،
            بما في ذلك إلى أي منظمة دولية تحكمها القانون الدولي العام أو أنشئت
            بواسطة دولتين أو أكثر، مثل الأمم المتحدة، ومعرفة التدابير الأمنية
            التي يتخذها المالك لحماية بياناتهم.
          </p>
        </SubSection>

        {/* Details About the Right to Object to Processing SubSection */}
        <SubSection>
          <h3>تفاصيل حول حق الاعتراض على المعالجة</h3>
          <p>
            حيثما تتم معالجة البيانات الشخصية لمصلحة عامة، أو في ممارسة سلطة
            رسمية مخولة للمالك، أو لأغراض المصالح المشروعة التي يسعى إليها
            المالك، يمكن للمستخدمين الاعتراض على هذه المعالجة من خلال تقديم سبب
            مرتبط بوضعهم الخاص لتبرير الاعتراض.
          </p>
          <p>
            يجب على المستخدمين أن يعلموا أنه، ومع ذلك، إذا تمت معالجة بياناتهم
            الشخصية لأغراض التسويق المباشر، يمكنهم الاعتراض على هذه المعالجة في
            أي وقت، مجانًا، ودون تقديم أي تبرير. حيثما يعترض المستخدم على
            المعالجة لأغراض التسويق المباشر، لن تتم معالجة البيانات الشخصية
            لأغراض مماثلة. لمعرفة ما إذا كان المالك يعالج البيانات الشخصية
            لأغراض التسويق المباشر، يمكن للمستخدمين الرجوع إلى الأقسام ذات الصلة
            من هذا المستند.
          </p>
        </SubSection>

        {/* How to Exercise These Rights SubSection */}
        <SubSection>
          <h3>كيفية ممارسة هذه الحقوق</h3>
          <p>
            يمكن توجيه أي طلبات لممارسة حقوق المستخدمين إلى المالك من خلال
            تفاصيل الاتصال المقدمة في هذا المستند. هذه الطلبات مجانية وسيتم الرد
            عليها من قبل المالك في أقرب وقت ممكن ودائمًا خلال شهر واحد، مع توفير
            المعلومات المطلوبة بموجب القانون. سيتم إبلاغ كل مستلم، إن وجد، عن أي
            تصحيح أو حذف للبيانات الشخصية أو تقييد للمعالجة من قبل المالك ما لم
            يكن ذلك مستحيلاً أو ينطوي على جهد غير متناسب. بناءً على طلب
            المستخدم، سيقوم المالك بإعلامهم عن هؤلاء المستلمين.
          </p>
        </SubSection>
      </Section>

      {/* Additional Information About Data Collection and Processing */}
      <Section>
        <SectionHeader>معلومات إضافية حول جمع ومعالجة البيانات</SectionHeader>

        {/* Legal Action SubSection */}
        <SubSection>
          <h3>الإجراءات القانونية</h3>
          <p>
            قد تُستخدم البيانات الشخصية للمستخدم لأغراض قانونية من قبل المالك في
            المحكمة أو في المراحل التي تسبق اتخاذ إجراء قانوني محتمل ناتج عن
            الاستخدام غير السليم لهذا التطبيق أو الخدمات المرتبطة به. يصرح
            المستخدم بأنه على علم بأن المالك قد يُطلب منه الكشف عن البيانات
            الشخصية بناءً على طلب السلطات العامة.
          </p>
        </SubSection>

        {/* Additional Information About User's Personal Data SubSection */}
        <SubSection>
          <h3>معلومات إضافية حول البيانات الشخصية للمستخدم</h3>
          <p>
            بالإضافة إلى المعلومات الواردة في سياسة الخصوصية هذه، قد يقدم هذا
            التطبيق للمستخدم معلومات إضافية وسياقية تتعلق بخدمات معينة أو جمع
            ومعالجة البيانات الشخصية بناءً على الطلب.
          </p>
        </SubSection>

        {/* System Logs and Maintenance SubSection */}
        <SubSection>
          <h3>سجلات النظام والصيانة</h3>
          <p>
            لأغراض التشغيل والصيانة، قد يجمع هذا التطبيق وأي خدمات طرف ثالث
            ملفات تسجل التفاعل مع هذا التطبيق (سجلات النظام) أو يستخدم بيانات
            شخصية أخرى (مثل عنوان IP) لهذا الغرض.
          </p>
        </SubSection>
      </Section>

      {/* Information Not Contained in This Policy */}
      <Section>
        <SectionHeader>معلومات غير واردة في هذه السياسة</SectionHeader>
        <p>
          يمكن طلب المزيد من التفاصيل المتعلقة بجمع أو معالجة البيانات الشخصية
          من المالك في أي وقت. الرجاء الاطلاع على معلومات الاتصال في بداية هذا
          المستند.
        </p>
      </Section>

      {/* Changes to This Privacy Policy */}
      <Section>
        <SectionHeader>تغييرات على سياسة الخصوصية هذه</SectionHeader>
        <p>
          يحتفظ المالك بالحق في إجراء تغييرات على سياسة الخصوصية هذه في أي وقت
          من خلال إشعار مستخدميه على هذه الصفحة وربما داخل هذا التطبيق
          و/أو—طالما كان ذلك ممكنًا تقنيًا وقانونيًا—إرسال إشعار إلى المستخدمين
          عبر أي معلومات اتصال متاحة للمالك. يُنصح بشدة بالتحقق من هذه الصفحة
          بانتظام، مع الإشارة إلى تاريخ التعديل الأخير المدرج في الأسفل.
        </p>
        <p>
          إذا أثرت التغييرات على أنشطة المعالجة التي تتم بناءً على موافقة
          المستخدم، سيجمع المالك موافقة جديدة من المستخدم عند الضرورة.
        </p>
      </Section>

      {/* Definitions and Legal References */}
      <Section>
        <SectionHeader>التعريفات والمراجع القانونية</SectionHeader>
        <List>
          <ListItem>
            <BoldText>البيانات الشخصية (أو البيانات):</BoldText> أي معلومات تسمح
            بتحديد هوية شخص طبيعي بشكل مباشر أو غير مباشر أو فيما يتعلق بمعلومات
            أخرى—بما في ذلك رقم تعريف شخصي—للتعرف على هذا الشخص.
          </ListItem>
          <ListItem>
            <BoldText>بيانات الاستخدام:</BoldText> المعلومات التي تُجمع تلقائيًا
            من خلال هذا التطبيق (أو خدمات طرف ثالث مستخدمة في هذا التطبيق)،
            والتي يمكن أن تشمل: عناوين IP أو أسماء النطاقات لأجهزة الكمبيوتر
            المستخدمة من قبل المستخدمين الذين يستخدمون هذا التطبيق، عناوين URI
            (معرف الموارد الموحد)، وقت الطلب، الطريقة المستخدمة لتقديم الطلب إلى
            الخادم، حجم الملف المستلم كرد، الكود الرقمي الذي يشير إلى حالة إجابة
            الخادم (نتيجة ناجحة، خطأ، إلخ)، بلد المنشأ، ميزات المتصفح ونظام
            التشغيل المستخدم من قبل المستخدم، التفاصيل الزمنية المختلفة لكل
            زيارة (مثل الوقت المستغرق على كل صفحة داخل التطبيق) وتفاصيل حول
            المسار المتبع داخل التطبيق مع إشارة خاصة إلى تسلسل الصفحات التي تمت
            زيارتها، ومعايير أخرى حول نظام تشغيل الجهاز و/أو بيئة تكنولوجيا
            المعلومات للمستخدم.
          </ListItem>
          <ListItem>
            <BoldText>المستخدم:</BoldText> الفرد الذي يستخدم هذا التطبيق والذي،
            ما لم يُذكر خلاف ذلك، يتطابق مع موضوع البيانات.
          </ListItem>
          <ListItem>
            <BoldText>موضوع البيانات:</BoldText> الشخص الطبيعي الذي تشير إليه
            البيانات الشخصية.
          </ListItem>
          <ListItem>
            <BoldText>معالج البيانات (أو المعالج):</BoldText> الشخص الطبيعي أو
            الاعتباري، السلطة العامة، الوكالة، أو الجسم الآخر الذي يعالج
            البيانات الشخصية نيابة عن المتحكم، كما هو موضح في سياسة الخصوصية
            هذه.
          </ListItem>
          <ListItem>
            <BoldText>متحكم البيانات (أو المالك):</BoldText> الشخص الطبيعي أو
            الاعتباري، السلطة العامة، الوكالة، أو الجسم الآخر الذي، بمفرده أو
            بشكل مشترك مع الآخرين، يحدد أغراض ووسائل معالجة البيانات الشخصية،
            بما في ذلك التدابير الأمنية المتعلقة بتشغيل واستخدام هذا التطبيق.
            متحكم البيانات، ما لم يُذكر خلاف ذلك، هو مالك هذا التطبيق.
          </ListItem>
          <ListItem>
            <BoldText>هذا التطبيق:</BoldText> الوسيلة التي يتم من خلالها جمع
            ومعالجة البيانات الشخصية للمستخدم.
          </ListItem>
          <ListItem>
            <BoldText>الخدمة:</BoldText> الخدمة المقدمة من هذا التطبيق كما هو
            موضح في الشروط ذات الصلة (إذا كانت متوفرة) وعلى هذا الموقع/التطبيق.
          </ListItem>
          <ListItem>
            <BoldText>الاتحاد الأوروبي (أو الاتحاد):</BoldText> ما لم يُذكر خلاف
            ذلك، تشمل جميع الإشارات الواردة في هذا المستند إلى الاتحاد الأوروبي
            جميع الدول الأعضاء الحالية في الاتحاد الأوروبي والمنطقة الاقتصادية
            الأوروبية.
          </ListItem>
          <ListItem>
            <BoldText>الكوكيز:</BoldText> الكوكيز هي أدوات تتبع تتكون من مجموعات
            صغيرة من البيانات المخزنة في متصفح المستخدم.
          </ListItem>
          <ListItem>
            <BoldText>أداة التحليل:</BoldText> تشير أدوات التحليل إلى أي
            تقنية—مثل الكوكيز، المعرفات الفريدة، علامات الويب، السكريبتات
            المضمنة، العلامات الإلكترونية، والبصمة الرقمية—التي تمكن من تتبع
            المستخدمين، على سبيل المثال، من خلال الوصول إلى المعلومات أو تخزينها
            على جهاز المستخدم.
          </ListItem>
          <ListItem>
            <BoldText>المعلومات القانونية:</BoldText> تتعلق سياسة الخصوصية هذه
            بهذا التطبيق فقط، ما لم يُذكر خلاف ذلك في هذا المستند.
          </ListItem>
        </List>
      </Section>

      <hr />
      <Footer>
        <p>
          <ItalicText>آخر تحديث: 27 أبريل 2024</ItalicText>
        </p>
      </Footer>
    </TermsContainer>
  );
};

export default Privacies;
