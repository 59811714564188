import React from "react";
import { Lesson } from "../components";

const Class = () => {
  return (
    <>
      <Lesson />
    </>
  );
};

export default Class;
