import React from "react";
import { Project } from "../components";

const Test = () => {
  return (
    <>
      <Project />
    </>
  );
};

export default Test;
