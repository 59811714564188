import React from "react";

import { Navbar, SignLog } from "../components";

const Login = () => {
  return (
    <>
      <Navbar />
      <SignLog />
    </>
  );
};

export default Login;
