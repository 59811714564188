import React from "react";
import { Course, FooterMe, NavAud } from "../components";

const Teach = () => {
  return (
    <>
      <NavAud />
      <Course />
      <FooterMe />
    </>
  );
};

export default Teach;
